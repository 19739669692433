
import { defineComponent, h, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue3-xlsx";
import moment from "moment";
import { ElMessage } from "element-plus";

moment.locale("fr");

export default defineComponent({
    name: "emailing",
    //components: { MemberCard, MemberFile, VueHtml2pdf, /*XlsxWorkbook, XlsxSheet, XlsxDownload,*/ Nomination, CDVCard, Diploma, LivreDor, Induction },
    data() {
        return {
            dialogVisible: false,
            percentage: 0,
            items: [1, 2, 3, 4, 5, 6, 7],
            sheets: [
                {
                    name: "Feuille 1",
                    data: [
                        {
                            ["Civilité"]: "",
                            ["Nom"]: "",
                            ["Prénom"]: "",
                            ["Grade"]: "",
                            ["Bailliage"]: "",
                            ["Mail"]: "",
                            ["Téléphone"]: "",
                            ["Pro"]: "",
                            ["OMGD"]: "",
                            ["Bailliage National"]: "",
                            ["Bailliage Provincial"]: "",
                            ["Bailliage Régional"]: "",
                            ["Adresse postale"]: "",
                            ["Adresse pro"]: "",
                        },
                        {
                            ["Civilité"]: "test",
                            ["Nom"]: "eee",
                            ["Prénom"]: "ddd",
                            ["Grade"]: "sss",
                            ["Bailliage"]: "qqq",
                            ["Mail"]: "SSSS",
                            ["Téléphone"]: "ggg",
                            ["Pro"]: "fff",
                            ["OMGD"]: "gdgf",
                            ["Bailliage National"]: "fdgdfg",
                            ["Bailliage Provincial"]: "dfgdgf",
                            ["Bailliage Régional"]: "dfgdfg",
                            ["Adresse postale"]: "gdfdfg",
                            ["Adresse pro"]: "dfgdgf",
                        },
                    ],
                },
            ],

            dashboardValues: [
                {
                    id: 0,
                    code: "1467",
                    member: "366143",
                    grade: "Argentier",
                    is_young_clerk: false,
                    is_young_sommelier: true,
                    is_pro: true,
                    is_omgd: false,
                    userprofile: {
                        firstname: "Jean",
                        lastname: "DUPONT",
                        national_bailliage: "France",
                        provincial_bailliage: "Poitou-Charentes",
                        regional_bailliage: "Deux-Sèvres",
                    },
                    requests: [
                        {
                            id: 0,
                            type: "Promotion",
                            relevant_year: "2022",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: true,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: null,
                        },
                        {
                            id: 1,
                            type: "Renewal",
                            relevant_year: "2022",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: true,
                            is_omgd: false,
                            date: "01/01/2022",
                            linked_request: null,
                        },
                        {
                            id: 2,
                            type: "Reinstatement",
                            relevant_year: "2022",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: false,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: [
                                {
                                    id: 100,
                                    type: "Renewal",
                                    relevant_year: "2025",
                                    perimeter: "CDR",
                                    price_category: "Young",
                                    amount: 31,
                                    is_pro: true,
                                    is_omgd: false,
                                    date: "01/01/2022",
                                },
                            ],
                        },
                        {
                            id: 3,
                            type: "Transfer",
                            relevant_year: "France",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: false,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: null,
                        },
                        {
                            id: 4,
                            type: "Admission",
                            relevant_year: "2022",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: true,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: null,
                        },
                    ],
                },
                {
                    id: 1,
                    code: "4722",
                    member: "373357",
                    grade: "Grand Officier Maitre Restaurateur",
                    is_young_clerk: false,
                    is_young_sommelier: false,
                    is_pro: true,
                    is_omgd: true,
                    userprofile: {
                        firstname: "Christian Greulich Højgod",
                        lastname: "SCHERRER-TOUMA",
                        national_bailliage: "France",
                        provincial_bailliage: "Poitou-Charentes",
                        regional_bailliage: "Deux-Sèvres",
                    },
                    requests: [
                        {
                            id: 10,
                            type: "Transfer",
                            relevant_year: "2022",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: false,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: null,
                        },
                        {
                            id: 11,
                            type: "Renewal",
                            relevant_year: "2022",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: true,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: null,
                        },
                    ],
                },
                {
                    id: 3,
                    code: "1467",
                    member: "366143",
                    grade: "Bailli délégué",
                    is_young_clerk: false,
                    is_young_sommelier: false,
                    is_pro: true,
                    is_omgd: true,
                    userprofile: {
                        firstname: "Ernst",
                        lastname: "PFISTER",
                        national_bailliage: "Austria",
                        provincial_bailliage: "Poitou-Charentes",
                        regional_bailliage: "Deux-Sèvres",
                    },
                    requests: [
                        {
                            id: 20,
                            type: "Promotion",
                            relevant_year: "2022",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: true,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: null,
                        },
                        {
                            id: 21,
                            type: "Renewal",
                            relevant_year: "2022",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: true,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: null,
                        },
                        {
                            id: 22,
                            type: "Reinstatement",
                            relevant_year: "2022",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: true,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: [
                                {
                                    id: 30,
                                    type: "Renewal",
                                    relevant_year: "2022",
                                    perimeter: "CDR",
                                    price_category: "Young",
                                    amount: 31,
                                    is_pro: true,
                                    is_omgd: true,
                                    date: "01/01/2022",
                                },
                                {
                                    id: 300,
                                    type: "Admission",
                                    relevant_year: "2026",
                                    perimeter: "OMGD",
                                    price_category: "Old",
                                    amount: 420,
                                    is_pro: true,
                                    is_omgd: true,
                                    date: "01/01/2022",
                                },
                            ],
                        },
                        {
                            id: 23,
                            type: "Transfer",
                            relevant_year: "France",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: true,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: null,
                        },
                        {
                            id: 24,
                            type: "Admission",
                            relevant_year: "2022",
                            perimeter: "CDR",
                            price_category: "Young",
                            amount: 31,
                            is_pro: true,
                            is_omgd: true,
                            date: "01/01/2022",
                            linked_request: null,
                        },
                    ],
                },
            ],
            loadingRows: [] as any,
        };
    },
    setup() {
        const router = useRouter();

        const htmlToPdfOptions = {
            margin: [10, 0, 20, 0],
        };

        onMounted(() => {
            setCurrentPageBreadcrumbs("Emailing", []);
        });
        const goToProForm = () => {
            router.push({ name: "pro-form", params: { token: "test_token" } });
        };

        const goToQrCode = () => {
            router.push({ name: "qr-code" });
        };
        return { goToProForm, goToQrCode, htmlToPdfOptions, router };
    },
    mounted() {
        // create array of false of all the rows
        this.loadingRows = Array(this.dashboardValues.length).fill({
            row: false,
            requests: [],
        });

        //fill the loadingRows.requests array with false
        this.loadingRows.forEach((row, index) => {
            this.loadingRows[index].requests = Array(
                this.dashboardValues[index].requests.length
            ).fill(false);
        });

        console.log(this.loadingRows);
    },
    methods: {
        remove(item) {
            const i = this.items.indexOf(item);
            if (i > -1) {
                this.items.splice(i, 1);
            }
        },
        appear(el, done) {
            el.style.opacity = 0;
            const delay = el.dataset.index * 50;
            setTimeout(() => {
                el.style.transition = "opacity 0.3s, transform 0.3s";
                el.style.opacity = 1;
                el.style.transform = "translateY(0)";
                done();
            }, delay);
        },
        removeRequest(id: any, action: string, requestId: any) {
            this.dashboardValues.map((row: any) => {
                row.requests = row.requests.filter(
                    (request: any) => request.id !== id
                );
                if (row.requests.length === 0)
                    this.dashboardValues = this.dashboardValues.filter(
                        (row: any) => row.requests.length !== 0
                    );
            });
            ElMessage({
                message: "Request " + action + ".",
                type: "success",
            });
        },
        removeRow(id: any, action: string, rowId: any) {
            this.dashboardValues = this.dashboardValues.filter(
                (row: any) => row.id !== id
            );
            ElMessage({
                message: "Requests group " + action + ".",
                type: "success",
            });
        },
        setProgress(e: any) {
            this.percentage = e;

            if (this.percentage === 100) {
                setTimeout(() => {
                    this.dialogVisible = false;
                    this.percentage = 0;
                }, 1000);
            }
        },
        printPDF(doc: any) {
            this.dialogVisible = true;
            switch (doc) {
                case "cdv_card":
                    (this.$refs.cdvCard as any).generatePdf();
                    break;
                case "nomination":
                    (this.$refs.nomination as any).generatePdf();
                    break;
                case "member_card":
                    (this.$refs.member_card as any).generatePdf();
                    break;
                case "diploma":
                    (this.$refs.diploma as any).generatePdf();
                    break;
                case "livre_dor":
                    (this.$refs.livre_dor as any).generatePdf();
                    break;
                case "induction":
                    (this.$refs.induction as any).generatePdf();
                    break;
                case "multiple_cdv_card":
                    (this.$refs.multiple_cdvCard as any).generatePdf();
                    break;
                case "multiple_nomination":
                    (this.$refs.multiple_nomination as any).generatePdf();
                    break;
                case "multiple_member_card":
                    (this.$refs.multiple_member_card as any).generatePdf();
                    break;
                case "multiple_diploma":
                    (this.$refs.multiple_diploma as any).generatePdf();
                    break;
                case "member_file":
                    (this.$refs.member_file as any).generatePdf();
                    break;
            }
        },
        editMember(member: any) {
            const routeData = this.router.resolve({
                name: "member-details",
                params: { member_id: member, page: 1 },
            });
            window.open(routeData.href, "_blank");
        },
        getBadgeType(type: string, scope: string) {
            if (scope === "color") {
                switch (type) {
                    case "Due":
                        return "badge-due";
                    case "new_pro_member":
                        return "badge-pro-member";
                    case "termination":
                        return "badge-election";
                    case "Transfer":
                        return "badge-transfer";
                    case "new_member":
                        return "badge-new-member";
                    case "pro_status":
                        return "badge-pro-status";
                    case "addresses":
                        return "badge-addresses";
                    case "Reinstatement":
                        return "badge-reinstatement";
                    case "Admission":
                        return "badge-admission";
                    case "Penalty":
                        return "badge-penalty";
                    case "Promotion":
                        return "badge-promotion";
                    case "Renewal":
                        return "badge-renewal";
                    case "adhesion":
                        return "badge-adhesion";
                    case "radiation":
                        return "badge-radiation";
                    case "omgd":
                        return "badge-omgd";
                }
            } else if (scope === "text") {
                switch (type) {
                    case "Due":
                        return "Due";
                    case "new_pro_member":
                        return "Nouveau membre Pro";
                    case "termination":
                        return "Résiliation";
                    case "Transfer":
                        return "Transfert";
                    case "new_member":
                        return "Nouveau membre";
                    case "pro_status":
                        return "Pro";
                    case "addresses":
                        return "Adresses";
                    case "Reinstatement":
                        return "Reinstatement";
                    case "Admission":
                        return "Admission";
                    case "Penalty":
                        return "Penalty";
                    case "Promotion":
                        return "Promotion";
                    case "Renewal":
                        return "Renewal";
                    case "Adhesion":
                        return "Adhésion";
                    case "Radiation":
                        return "Radiation";
                    case "omgd":
                        return "OMGD";
                }
            }
        },
    },
});
